import styled from 'styled-components';

import { Box } from 'components/Box/Box';
import { FilestackImage } from 'components/FilestackImage/FilestackImage';
import { ConditionalStyledLinkWithRef } from 'components/Link/ConditionalStyledLinkWithRef';
import {
  CmsApiSplitBlockAppearance,
  CmsApiSplitBlockImage,
} from 'modules/cms/api/types/blocks/CmsApiSplitContentBlock';
import { gutterWidth } from 'theme/theme';

export const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

type Props = {
  appearance: CmsApiSplitBlockAppearance;
  blockId: string;
  section: CmsApiSplitBlockImage;
  showBackgroundImage: boolean;
  imageTextBlock: boolean;
  imageQuoteBlock: boolean;
  index: number;
  preview: boolean;
};

export function CmsSplitContentImageContent({
  appearance,
  blockId,
  imageTextBlock,
  imageQuoteBlock,
  index,
  preview,
  section,
  showBackgroundImage,
}: Props) {
  const overlapping = appearance === 'OVERLAPPING';
  const fullWidth = appearance === 'FULL_WIDTH';

  if (showBackgroundImage) return null;

  return (
    <Box
      data-qa-id={`split-content-section-${index}`}
      // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'Position | ... Remove this comment to see the full error message
      position={[undefined, null, !fullWidth ? 'relative' : undefined]}
      width={[1, null, 1 / 2]}
      px={[imageTextBlock ? '18px' : gutterWidth / 4, null, gutterWidth / 2]}
      py={[
        imageTextBlock ? gutterWidth / 2 : gutterWidth / 4,
        null,
        gutterWidth / 2,
      ]}
      key={blockId}
    >
      <Box
        // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'Position | ... Remove this comment to see the full error message
        position={[null, null, fullWidth && !preview ? 'absolute' : undefined]}
        left={[null, null, '50%']}
        top={[null, null, '50%']}
        transform={[
          null,
          null,
          fullWidth && !preview ? 'translate(-50%, -50%)' : null,
        ]}
        height={
          section.data.size === 'FIXED_WIDTH'
            ? 'auto'
            : [
                '100%',
                null,
                overlapping || fullWidth
                  ? `calc(100% + ${
                      overlapping ? gutterWidth * 2 : gutterWidth
                    }px)`
                  : '100%',
              ]
        }
        // @ts-expect-error TS(2322): Type 'false | (number | null)[]' is not assignable... Remove this comment to see the full error message
        mt={overlapping && [0, null, -gutterWidth]}
        width={
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line no-nested-ternary
          overlapping
            ? [1, null, `calc(100% + ${gutterWidth * 3}px)`]
            : fullWidth
              ? [1, null, '100vw']
              : 1
        }
        // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string | nu... Remove this comment to see the full error message
        ml={[0, null, overlapping && index === 1 && -gutterWidth * 3]}
        overflow="hidden"
      >
        <ConditionalStyledLinkWithRef
          link={section.data.url}
          data-qa-id="split-content-section-image-url"
        >
          <ImageContainer>
            <FilestackImage
              data-qa-id="split-content-section-image"
              data={section.data.image}
              alt={
                (section.data.image && section.data.image.altText) ||
                (section.data.image && section.data.image.filename)
              }
              // TODO: Fix this the next time the file is edited.
              // eslint-disable-next-line no-nested-ternary
              width={fullWidth ? 1440 : overlapping ? 696 : 552}
              expandSize={section.data.size !== 'FIXED_WIDTH'}
              objectFit={
                section.data.size !== 'FIXED_WIDTH' ? 'cover' : 'contain'
              }
              style={{
                borderRadius:
                  (imageTextBlock || imageQuoteBlock) && !fullWidth
                    ? '10px'
                    : undefined,
              }}
            />
          </ImageContainer>
        </ConditionalStyledLinkWithRef>
      </Box>
    </Box>
  );
}
